/* App.css */


body {
  /* padding: 5%; */
  /* font-family: 'IRANSansX', sans-serif; */
  /* background-color: #F5F5F5; */
}

.row {
  margin: auto;
}

.container {
  padding: 0;
  display: flex;
  background-color: #FFF;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  /* height: 700px; */
  /* position: relative; */

}


/* === CONVERSATIONS === */

.discussions {
  width: 100%;
  height: 700px;
  box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.20);
  overflow: hidden;
  background-color: #87a3ec;
  display: inline-block;
}

.discussions .discussion {
  width: 100%;
  height: 90px;
  background-color: #FAFAFA;
  border-bottom: solid 1px #E0E0E0;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.discussions .search {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #E0E0E0;
}

.discussions .search .searchbar {
  height: 40px;
  background-color: #FFF;
  width: 70%;
  padding: 0 20px;
  border-radius: 50px;
  border: 1px solid #EEEEEE;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.discussions .search .searchbar input {
  margin-left: 15px;
  height: 38px;
  width: 100%;
  border: none;
  font-family: 'Montserrat', sans-serif;
  ;
}

.discussions .search .searchbar *::-webkit-input-placeholder {
  color: #E0E0E0;
}

.discussions .search .searchbar input *:-moz-placeholder {
  color: #E0E0E0;
}

.discussions .search .searchbar input *::-moz-placeholder {
  color: #E0E0E0;
}

.discussions .search .searchbar input *:-ms-input-placeholder {
  color: #E0E0E0;
}

.discussions .message-active {
  width: 98.5%;
  height: 90px;
  background-color: #FFF;
  border-bottom: solid 1px #E0E0E0;
}

.discussions .discussion .photo {
  margin-left: 20px;
  display: block;
  min-width: 45px;
  width: 45px;
  height: 45px;
  background: #E6E7ED;
  -moz-border-radius: 50px;
  -webkit-border-radius: 50px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.online {
  position: relative;
  top: 30px;
  left: 35px;
  width: 13px;
  height: 13px;
  background-color: #8BC34A;
  border-radius: 13px;
  border: 3px solid #FAFAFA;
}

.offline {
  position: relative;
  top: 30px;
  left: 35px;
  width: 13px;
  height: 13px;
  background-color: #c3684a;
  border-radius: 13px;
  border: 3px solid #FAFAFA;
}

.desc-contact {
  height: 43px;
  width: 50%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.discussions .discussion .name {
  margin: 0 0 0 20px;
  font-family: 'Montserrat', sans-serif;
  font-size: 11pt;
  color: #515151;
}

.discussions .discussion .message {
  margin: 6px 0 0 20px;
  font-family: 'Montserrat', sans-serif;
  font-size: 9pt;
  color: #515151;
}

.timer {
  margin-left: 15%;
  font-family: 'Open Sans', sans-serif;
  font-size: 11px;
  padding: 3px 8px;
  color: #BBB;
  background-color: #FFF;
  border: 1px solid #E5E5E5;
  border-radius: 15px;
}

.chat {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.header-chat {
  background-color: #4b7ff8;
  height: 155px;
  box-shadow: 0px 3px 2px rgba(0, 0, 0, 0.100);
  display: flex;
  align-items: center;
  flex: none
}

.chat .header-chat .icon {
  margin-left: 30px;
  color: #515151;
  font-size: 14pt;
}

.chat .header-chat .name {
  margin: 0 0 0 0;
  text-transform: uppercase;
  font-family: 'Montserrat', sans-serif;
  font-size: 12pt;
  color: #515151;
}

.chat .header-chat .right {
  position: absolute;
  right: 40px;
}

.chat .messages-chat {
  height: 453px;
  overflow-y: scroll;
  padding: 25px 20px;
  padding-right: 0px;
}

.chat .messages-chat.rating {
  overflow-y: hidden;
}

.chat .messages-chat .message {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.chat .messages-chat .message .photo {
  display: block;
  width: 45px;
  height: 45px;
  min-width: 45px;
  background: #E6E7ED;
  -moz-border-radius: 50px;
  -webkit-border-radius: 50px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.chat .messages-chat .text {
  margin: 0 10px;
  margin-right: 8px;
  background-color: #f6f6f6;
  padding: 15px;
  border-radius: 12px;
}

.text-only {
  margin-left: 45px;
}

.text {
  font-size: 14px
}

.chat-left {
  text-align: justify;
  margin-right: 50px !important;
}

.time {
  font-size: 10px;
  color: lightgrey;
  margin-bottom: 10px;
  /* margin-left: 85px; */
  display: flex;
  justify-content: center;
}

/* .response-time {
  float: right;
  margin-right: 40px !important;
} */

.response {
  float: right;
  margin-right: 0px !important;
  margin-left: auto;
  /* flexbox alignment rule */
}

.response .text {
  direction: rtl;
  background-color: #e3effd !important;
}

.footer-chat {
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
  /* position: absolute; */
  bottom: 0;
  background-color: transparent;
  /* border-top: 2px solid #EEE; */

}

.chat .footer-chat .icon {
  margin-left: 30px;
  color: #C0C0C0;
  font-size: 14pt;
}

.chat .footer-chat .send {
  color: #fff;
  background-color: #4f6ebd;
  position: absolute;
  left: 50px;
  padding: 12px 12px 12px 12px;
  border-radius: 50px;
  font-size: 14pt;
}

.chat .footer-chat .name {
  margin: 0 0 0 20px;
  text-transform: uppercase;
  font-family: 'Montserrat', sans-serif;
  font-size: 13pt;
  color: #515151;
}

.chat .footer-chat .right {
  position: absolute;
  right: 40px;
}

.write-message {
  border: none !important;
  font-family: 'IRANSansX', sans-serif;
  outline: none !important;

  width: 85%;
  /* height: 50px; */
  margin-left: 20px;
  padding: 10px;
}

.footer-chat *::-webkit-input-placeholder {
  color: #C0C0C0;
  font-family: 'IRANSansX', sans-serif;
  font-size: 10pt;
}

.footer-chat input *:-moz-placeholder {
  color: #C0C0C0;
  font-family: 'IRANSansX', sans-serif;
  font-size: 10pt;
}

.footer-chat input *::-moz-placeholder {
  color: #C0C0C0;
  font-size: 10pt;
  font-family: 'IRANSansX', sans-serif;
  margin-left: 5px;
}

.footer-chat input *:-ms-input-placeholder {
  color: #C0C0C0;
  font-size: 10pt;
  font-family: 'IRANSansX', sans-serif;

}

.clickable {
  cursor: pointer;
}

.floating-chat {
  position: fixed !important;
  z-index: 999999;
  right: 50px;
  bottom: 128px;
  width: 400px;
  border-radius: 10px;
  overflow: hidden;
}

.fullscreen-chat {
  position: fixed !important;
  z-index: 999999;
  right: 0px;
  left: 0px;
  top: 0px;
  bottom: 0px;
  overflow: hidden;
  height: 100%;
}

.fullscreen-chat .chat {
  display: flex;
  height: 100dvh;
}

.fullscreen-chat .messages-chat {
  height: auto;
  flex: 1
}

.profile-prev {
  box-sizing: border-box;
  border-radius: 100%;
  margin-left: -6px;
  /* Negative margin for the overlap */
  /* height: 35px; */
  /* width: 12%; */
  height: 50px;
  border-color: #3d7af6;
  border-style: solid;
  /* position: absolute; */
  position: relative;
  border-width: 0px;



}

img.profile-prev {
  border-width: 4px;
  background-color: rgb(173, 173, 173);
  width: 50px;
  height: 50px;
  max-width: 50px;
  max-height: 50px;
  object-fit: cover;
}


div.profile-prev-title {
  position: absolute;
  color: red;
  font-weight: bold;
  background: white;
  padding: 5px;
  border-radius: 8px;
  margin: auto;
  left: 0;
  color: black;
  font-family: 'IRANSansX';
  font-size: 11px;
  z-index: 999;
  top: -32px;
}

div.profile-prev-title .tail {
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0) rotate(45deg);
  height: 10px;
  width: 10px;
  background-color: rgb(255, 255, 255);
}



@keyframes rotateSlowly {

  0%,
  100% {
    transform: rotate(-30deg);
  }

  50% {
    transform: rotate(0deg);
  }
}

.rotating-element {
  animation: rotateSlowly 100s linear infinite;
  background-image: url("./images/image.png") !important;

}

.text {
  overflow-wrap: anywhere;
}



@keyframes rotateAnimation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.rotate-pending-message {
  animation: rotateAnimation 1s linear infinite;
  /* Adjust the duration and other properties as needed */
}


.audio-message {
  background: #e3effd;
  padding: 12px;
  width: 230px;
  border-radius: 9px;
  margin-bottom: 8px;
  float: right;
  display: flex;
  margin-right: 7px;
}

.received .audio-message {
  background: #f6f6f6 !important;
  margin-left: none;
}

.received {
  margin-left: 79px;
}

.play-btn-container {
  display: flex;
  padding: 11px;
  background: rgb(66 124 247);
  cursor: pointer;
  border-radius: 52px;
  color: white;
}

.play-bar-container {
  display: flex;
  /* background-color: pink; */
  padding-left: 10px;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  flex: 1
}

.play-bar-holder {
  height: 2px;
  width: 100%;
  display: flex;
  /* background-color: brown; */
  flex: 4
}

.play-bar {
  width: 100%;
  height: 4px;
  background-color: gray;
  align-self: center;
}

.play-bar-filled {
  height: 4px;
  background-color: rgb(66 124 247);
  align-self: center;
}

.play-time {
  align-self: flex-start;
  flex: 1;
  font-size: 10px;
}

.crisp-image-container {
  padding: 6px;
}


.crisp-image {
  max-width: 200px;
  max-height: 200px;
}


.download-image-overlay {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  /* background: red; */
  text-align: center;
  justify-content: center;
  display: flex;
  align-items: center;
}

.download-image-btn {
  padding: 6px;
  background: #427cf7;
  border-radius: 7px;
  cursor: pointer;
  font-family: 'IRANSansX';
  font-size: smaller;
  color: white;
}


.image-loading {
  animation: rotateAnimation 1s linear infinite;
  /* Adjust the duration and other properties as needed */
}


.sending-file {
  position: absolute;
  top: -21px;
  right: 19px;
  font-family: 'IRANSansX';
  font-size: 11px;
  color: #616161;

}






@media only screen and (max-height: 856px) {
  .chat {
    height: 650px;
  }
}

@media only screen and (max-height: 770px) {
  .chat {
    height: 603px;
  }
}


@media only screen and (max-height: 740px) {
  .chat {
    height: 600px;
  }
}

@media only screen and (max-height: 710px) {
  .chat {
    height: 500px;
  }
}

.switch-item {
  padding: 3px 11px 3px 11px;
  margin: 2px;
  border-radius: 9px;
  font-size: 12pt;
  cursor: pointer;
}

.switch-item.active {
  background: #427cf7;
}


.switch-item p {

  font-size: 9pt !important;
}

.switch-item .name {
  display: flex;
}

.phone-email-btn {
  padding: 5px;
  display: flex;
  justify-content: center;
  border: #2871e7 1px solid;
  border-radius: 11px;
  font-size: 13px;
  cursor: pointer;
  margin-top: 10px;
  margin-left: 4px;
  color: #2871e7
}

.response .text.blue-bubble {
  background-color: #2871e7 !important;
  color: white
}



.rate-tooltip {
  /* writing-mode: vertical-rl; */
  /* text-orientation: mixed; */
  text-wrap: nowrap;
  position: absolute;
  font-family: 'IRANSansX';
  height: 100px;
  top: 130%;
  color: black;
  font-size: 12px;
  display: flex;

  border: 1px red solid;
  border: 1px #001df891 solid;
  height: max-content;
  border-radius: 7px;
  border-top-width: 0;
  /* flex-direction: ; */
}

.rate-tooltip>div {
  background-color: white;
  padding: 3px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 5px;
  position: relative;
}

.rate-tooltip>div::after {
  background-color: white;
  padding: 7px;
  transform: translate(-50%, 0) rotate(45deg);
  left: 50%;
  top: -4px;
  margin-bottom: 10px;
  z-index: -1;
  content: '';
  position: absolute;
  align-self: center;

}


.messages-chat {
  /* position: relative; */
}

.rate-container {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 9999;
  backdrop-filter: blur(3px);
  animation: fadeIn 0.5s;
  display: flex;
  justify-content: center;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.rate-shadow {
  -webkit-box-shadow: -2px 8px 81px -25px rgba(0, 0, 0, 0.53);
  -moz-box-shadow: -2px 8px 81px -25px rgba(0, 0, 0, 0.53);
  box-shadow: -2px 8px 81px -25px rgba(0, 0, 0, 0.53);
}

.sumbit-comment {
  width: 100%;
  background: #427cf7;
  text-align: center;
  color: white;
  padding: 11px;
  border-radius: 5px;
  margin-top: 10px;
  font-size: 18px;
  cursor: pointer;
  height: 32px;
  display: flex;
  justify-content: center;
}

.rate-container textarea {
  direction: rtl;
  width: 90%;
  padding: 10px;
  border-radius: 5px;
  border-width: 1px;
  border-color: #427cf7;
  margin-top: 10px;
  background: #0082ff05;
  font-size: 16px;

}

.rate-container {
  /* max-width: 580px; */
}

.rate-container textarea ::placeholder {
  font-family: "IRANSansX";
}

.rate-selected {
  font-size: 24px;
  padding: 8px;
  border-radius: 100%;
  background: #eeeeee;
  color: #171717;
  width: 50px;
  height: 50px;
  text-align: center;
  display: flex;
  justify-content: center;
}

.operator_name {
  color: #878787;
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: -16px;
  font-family: 'IRANSansX';
  font-size: 10px;
  text-align: center;
}